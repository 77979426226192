<script>
import ResourceFrame from "../components/ResourceFrame.vue";
import codeOfConductHero from "../../../../public/img/resources/code-of-conduct-hero.png";
import { SuawInputGroup, SuawParagraph, SuawHeading, SuawThumbnail } from "@suaw/suaw-component-library";
export default {
  name: "ResourcesCodeOfConduct",
  components: { ResourceFrame, SuawInputGroup, SuawHeading, SuawParagraph, SuawThumbnail },
  data() {
    return {
      codeOfConductHero
    };
  }
};
</script>

<template>
  <ResourceFrame>
    <SuawInputGroup direction="column" group-gap="quadruple" prevent-flex-grow>
      <h1 class="suaw-code-of-conduct-heading">Code of <b>Conduct</b></h1>
      <SuawHeading
        class="suaw-code-of-conduct-intro"
        level="3"
        content="Welcome to Shut Up & Write! As a community built by writers, for writers, we are committed to creating a safe, inclusive, and positive environment for our members. To do this, we need the help and commitment of every person in our community. We ask that those who attend our events or participate on our site respect the rules and guidelines outlined below."
      />
      <SuawThumbnail class="suaw-code-of-conduct-thumbnail" :content="codeOfConductHero" />
      <h2 class="suaw-code-of-conduct-subtitle">
        Our Vision
      </h2>
      <SuawParagraph
        text="Shut Up & Write! provides writers with the community, accountability, and resources they need to get their writing done. To accomplish this, we have cultivated a community, both in-person and online, that embraces the following values:"
      />
      <div class="suaw-code-of-conduct-guide">
        <ul class="suaw-code-of-conduct-guide__list">
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph text="Inclusivity" />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph text="Kindness" />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph text="Respect" />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph text="Support" />
          </li>
        </ul>
      </div>
      <h2 class="suaw-code-of-conduct-subtitle">
        Inclusivity
      </h2>
      <SuawParagraph
        text="We value diversity, and are committed to creating an inclusive culture for members of all races, gender identities, sexual orientations, disabilities, ethnicities, and religions."
      />
      <div class="suaw-code-of-conduct-guide">
        <ul class="suaw-code-of-conduct-guide__list">
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Hate speech:</b> The Oxford English Dictionary defines hate speech as “speech or writing that attacks or threatens a particular group of people, especially on the basis of race, religion, or sexual orientation.” We do not tolerate hate speech in any form, and this behavior will result in immediate expulsion from our community."
            />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Discrimination:</b> We do not tolerate any behavior that excludes members based on gender, race, sexuality, ethnicity, or nationality. If a particular identity to a group or individual is not included in this list, we welcome you too, and want you to know that we respect all of our members."
            />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Preferred pronouns:</b> We encourage the use of pronouns in user profiles in order to make it easier for all members to share their preferred pronouns online. We encourage our members to share and respect pronoun preferences at in-person events."
            />
          </li>
        </ul>
      </div>
      <h2 class="suaw-code-of-conduct-subtitle">
        Kindness
      </h2>
      <SuawParagraph text="Our community celebrates kindness and positivity." />
      <div class="suaw-code-of-conduct-guide">
        <ul class="suaw-code-of-conduct-guide__list">
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Harassment:</b> We do not tolerate harassment of our members in any form. This includes unwanted advances, verbal abuse, bullying, or any other harassing behavior that makes other members feel threatened or unsafe."
            />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph text="<b>Violence:</b> We do not tolerate violent or illegal behavior." />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Personal attacks:</b> Remember to criticize ideas, not people. Personal attacks, name-calling, trolling, and other negative behavior will not be tolerated."
            />
          </li>
        </ul>
      </div>
      <h2 class="suaw-code-of-conduct-subtitle">
        Respect
      </h2>
      <SuawParagraph text="Respect your fellow members in all Shut Up & Write spaces. Remember that behind each online profile is a real person." />
      <div class="suaw-code-of-conduct-guide">
        <ul class="suaw-code-of-conduct-guide__list">
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Violation of privacy or security:</b> We do not tolerate any violations of another member's privacy or security, including sharing personal information without another member's consent."
            />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Consent:</b> Shut Up & Write! defines consent as welcomed communications and actions to engage with a member. Engaging with anyone in a non-consensual way is strictly prohibited. If ever in doubt, ask permission."
            />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Distraction-free environment:</b> All members are expected to help maintain a quiet, distraction-free environment during the writing portion of Shut Up & Write! events."
            />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Appropriate behavior and language:</b> Gratuitously graphic, disgusting, obscene, sexual, or violent content is prohibited in member communications. We don't allow content posted in member spaces to upset, shock, or disturb a Shut Up & Write! community or its members."
            />
          </li>
        </ul>
      </div>
      <h2 class="suaw-code-of-conduct-subtitle">
        Support
      </h2>
      <SuawParagraph
        text="We want all of our members to feel supported in their writing practice. Help us by supporting your fellow writers rather than creating distractions."
      />
      <div class="suaw-code-of-conduct-guide">
        <ul class="suaw-code-of-conduct-guide__list">
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Overt sales and solicitation:</b> Please do not use our platform to broadly market your other affiliations, paid goods and services, or events occurring outside the Shut Up & Write community."
            />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph text="<b>Conspiracy theories:</b> Please do not use our platform to promote theories that are harmful and nonfactual." />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Ask before editing:</b> Not everyone is comfortable with having their work edited or corrected in a public forum. Before criticizing or offering corrections on writing mechanics, grammar, style, spelling, or other issues, please ask first."
            />
          </li>
        </ul>
      </div>
      <h2 class="suaw-code-of-conduct-subtitle">
        Other Guidelines
      </h2>
      <SuawParagraph text="<b>Age Requirements</b>" />
      <div class="suaw-code-of-conduct-guide">
        <ul class="suaw-code-of-conduct-guide__list">
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Minors allowed, with supervision:</b> We know that minors also love to write. We allow participation in our events by those under 18 years of age with the supervision of a parent or guardian present during the Shut Up & Write! activity."
            />
          </li>
          <li class="suaw-code-of-conduct-guide__item">
            <SuawParagraph
              text="<b>Content warnings:</b> We also understand that adult writers may create content that is not always appropriate for minors. Please consider including a content warning if you are sharing work online that is inappropriate for minors, or contains sensitive topics."
            />
          </li>
        </ul>
      </div>
      <SuawParagraph text="<b>Discussions, Challenges, Prompts: Stay On Topic</b>" />
      <SuawParagraph
        text="Our Discussions are a place for writers to connect with each other and share ideas related to writing and the writer's lifestyle. Similarly, our Challenges and Prompts are intended to spark creativity and writing-related discussions. These online spaces depend on your participation and cooperation. Please remember to keep the discussion writing-focused, and support your fellow writers by cheering them on."
      />
      <SuawParagraph text="<b>Only Post Your Own Work</b>" />
      <SuawParagraph
        text="Remember, you may not post anything digital that belongs to someone else without permission. You may not post descriptions of, links to, or methods for breaking any law, or stealing someone's intellectual property."
      />
      <h2 class="suaw-code-of-conduct-subtitle">
        The Bottom Line
      </h2>
      <SuawParagraph
        text="In order to maintain our online community, our team reserves the right to remove any content and any user account for any reason, at any time. Our team does not preview new posts in any way; the moderators and site operators take no responsibility for any content posted by the community."
      />
      <SuawParagraph text="Any violation of the rules outlined above will result in disciplinary action at our discretion." />
      <SuawParagraph text='Please also read our other policies, which complement this Code of Conduct. Like our <a href="/resources/tos">Terms of Use</a>.' />
    </SuawInputGroup>
  </ResourceFrame>
</template>

<style lang="scss">
.suaw-code-of-conduct-heading {
  margin: 0;
  padding: 0;
  color: #414142;
  font-family: Archer;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}
.suaw-code-of-conduct-intro {
  font-family: Roboto;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  color: var(--sem-color-neutral-dark);
}
.suaw-code-of-conduct-subtitle {
  color: #414141;
  font-family: Archer;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}
.suaw-code-of-conduct-thumbnail {
  aspect-ratio: 2;
  @media (max-width: 650px) {
    aspect-ratio: 1.5;
  }
}
//list
.suaw-code-of-conduct-guide {
  font-family: Roboto;
  color: var(--sem-color-text-medium);

  &__list {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item::marker {
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
